<template>
    <div>
        <!-- <myAccount></myAccount> -->
        <div class="accountUserForm">
            <div class="smartphone-back-button">
                <router-link :to="{path:'/myAccount'}">
                    <span class="icon"></span>
                    <span>Back</span>
                </router-link>
            </div>
            <p>Save, add and edit your account information.</p>
            <div class="profile-form-container profileForm">
                <el-form
                    ref="registerForm"
                    :rules="editInfoRules"
                    :label-position="labelPosition"
                    :model="registerForm"
                    class="demo-ruleForm"
                    size="small"
                    :hide-required-asterisk="true"
                    @submit.native.prevent
                >
                    <div class="serverSideErrors"></div>
                    <div class="changeEmailPasswordContainer">
                        <div class="emailFieldContainer">
                            <span>Email address: {{ this.$store.getters.userInfo.email }}</span>
                            <button class="editUserPasswordButton" @click="changeEmails">Change your email address
                            </button>
                        </div>
                        <div class="passwordFieldContainer">
                            <span>Password: xxxxxxxxxxx</span>
                            <button class="editUserPasswordButton" @click="chagnePassword">Change your password</button>
                        </div>
                    </div>
                    <div class="requiredFieldLabel mandatory">* Required fields</div>
                    <div class="Name">
                        <el-form-item label="First Name *" prop="firstName">
                            <el-input v-model="registerForm.firstName" placeholder="First Name"></el-input>
                        </el-form-item>
                    </div>
                    <div class="Surname">
                        <el-form-item label="Last Name *" prop="lastName">
                            <el-input v-model="registerForm.lastName" placeholder="Last Name"></el-input>
                        </el-form-item>
                    </div>
                    <div class="BirthDate">
                        <el-form-item label="Date of birth" prop="confPass" class="dateBirth">
                            <div class="block">
                                <el-date-picker
                                    v-model="dateValue"
                                    type="date"
                                    placeholder="Date of birth"
                                    class="dateEletion">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="privacyContainer">
                        In order to enhance your experience on bingxu.com, personal data will be electronically
                        processed by BING
                        XU. Your data will be strictly controlled by BING XU for customer management, marketing and
                        other purposes
                        as detailed in our
                        <a href="#">Privacy Policy</a>
                    </div>
                    <div class="acceptFlags">
                        <el-checkbox-group v-model="checkList">
                            <el-checkbox
                                label="I agree that BING XU may collect my personal information for marketing purposes (newsletters, updates and collection arrivals etc.)."></el-checkbox>
                            <el-checkbox
                                label="I consent to the processing of my personal data by BING XU for customer satisfaction purposes and for customizing my user experience to my interests or my shopping habits."></el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="buttonsRow" @click="save('registerForm')">
                        <button class="submit" type="button">Save</button>
                    </div>
                </el-form>
            </div>
        </div>
        <el-drawer
            :visible.sync="emailChanges"
            size="100%"
            direction="btt">
            <div class="emailchangeBox">
                <div class="mfp-content-flex-item">
                    <div class="mfp-content-holder">
                        <div class="mandatory">* Required fields</div>
                        <div class="oldEmail">
                            <span>Current email address: </span>
                            <span>{{ this.$store.getters.userInfo.email }}</span>
                        </div>
                        <!--  修改邮箱            -->
                        <el-form
                            ref="editEmailForm"
                            :rules="editEmailRules"
                            :label-position="labelPosition"
                            :model="editEmailForm"
                            class="demo-ruleForm"
                            size="small"
                            :hide-required-asterisk="true"
                            v-if="this.isEmail == 1"
                        >
                            <el-form-item label="Password *" prop="password">
                                <el-input v-model="editEmailForm.password" placeholder="Password"
                                          type="password"></el-input>
                            </el-form-item>
                            <el-form-item label="Email address *" prop="newEmail">
                                <el-input v-model="editEmailForm.newEmail" placeholder="New email address"></el-input>
                            </el-form-item>
                            <el-form-item label="Confirm email address *" prop="confirmNewEmail">
                                <el-input v-model="editEmailForm.confirmNewEmail"
                                          placeholder="Confirm email address"></el-input>
                            </el-form-item>
                            <div class="buttonsRow" @click="saveEditEmail('editEmailForm')">
                                <button type="button">Save</button>
                            </div>
                        </el-form>
                        <!--  修改密码            -->
                        <el-form
                            ref="editPasswordForm"
                            :rules="editPasswordRules"
                            :label-position="labelPosition"
                            :model="editPasswordForm"
                            class="demo-ruleForm" size="small"
                            :hide-required-asterisk="true"
                            v-if="this.isEmail == 2"
                        >
                            <el-form-item label="Password *" prop="password">
                                <el-input v-model="editPasswordForm.password" placeholder="Password"
                                          type="password"></el-input>
                            </el-form-item>
                            <el-form-item label="New Password *" prop="newPassword">
                                <el-input v-model="editPasswordForm.newPassword" placeholder="New Password"
                                          type="password"></el-input>
                            </el-form-item>
                            <el-form-item label="Confirm new Password *" prop="confirmNewPassword">
                                <el-input v-model="editPasswordForm.confirmNewPassword"
                                          placeholder="Confirm new Password" type="password"></el-input>
                            </el-form-item>
                            <div class="buttonsRow" @click="saveEditPassword('editPasswordForm')">
                                <button type="button">Save</button>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
// import myAccount from "@/views/myAccount";
import {editUserEmail, editUserInfo, editUserPassword} from "@/api/api";

export default {
    name: "profile",
    components: {
        // myAccount
    },
    data() {
        return {
            dateValue: "",
            checkList: [],
            labelPosition: "top",
            isEmail: 1,
            value: "",
            emailChanges: false,
            editInfoRules: {
                firstName: [
                    {required: true, message: "The name field is required", trigger: "blur"}
                ],
                lastName: [
                    {required: true, message: "The last name field is required", trigger: "blur"}
                ],
            },
            registerForm: {
                firstName: "",
                lastName: ""
            },
            editEmailForm: {
                password: "",
                newEmail: "",
                confirmNewEmail: ""
            },
            editPasswordForm: {
                password: "",
                newPassword: "",
                confirmNewPassword: ""
            },
            editEmailRules: {
                password: [
                    {required: true, message: "The password field is required", trigger: "blur"}
                ],
                newEmail: [
                    {type: "email", required: true, message: "The new email field is required", trigger: "blur"}
                ],
                confirmNewEmail: [
                    {type: "email", required: true, message: "The confirm emaill field is required", trigger: "blur"}
                ]
            },
            editPasswordRules: {
                password: [
                    {required: true, message: "The password field is required", trigger: "blur"}
                ],
                newPassword: [
                    {required: true, message: "The new password field is required", trigger: "blur"}
                ],
                confirmNewPassword: [
                    {required: true, message: "The confirm password field is required", trigger: "blur"}
                ]
            }
        };
    },
    mounted() {
        this.fetchUserInfo();
    },
    methods: {
        changeEmails() {
            this.isEmail = 1;
            this.emailChanges = true;
        },
        chagnePassword() {
            this.isEmail = 2;
            this.emailChanges = true;
        },
        async fetchUserInfo() {
            const accessToken = sessionStorage.getItem("accessToken");
            if (accessToken) {
                this.$store
                    .dispatch("user/getInfo")
                    .then(userInfo => {
                        if (userInfo.birth)
                            this.dateValue = this.$moment(userInfo.birth);
                        this.registerForm = {
                            firstName: userInfo.firstName,
                            lastName: userInfo.lastName
                        };
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                console.log("没有token");
            }
        },
        save(formName) {
            if (this.checkList.length < 2) {
                this.$message({
                    showClose: true,
                    message: 'Please check to agree to the relevant agreement',
                    type: "error"
                });
                return;
            }
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.dateValue) {
                        this.registerForm.birthYear = this.$moment(this.dateValue).format("YYYY");
                        this.registerForm.birthMonth = this.$moment(this.dateValue).format("MM");
                        this.registerForm.birthDay = this.$moment(this.dateValue).format("DD");
                    }
                    const res = await editUserInfo(this.registerForm);
                    const {code, message} = res;
                    if (code !== 0) {
                        console.log(message);
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.fetchUserInfo();
                        this.$message({
                            showClose: true,
                            message: "Success",
                            type: "success"
                        });
                    }
                    console.log(res);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        saveEditEmail(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const res = await editUserEmail(this.editEmailForm);
                    const {code, message} = res;
                    if (code !== 0) {
                        console.log(message);
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.fetchUserInfo();
                        this.emailChanges = false;
                        this.$message({
                            showClose: true,
                            message: "Success",
                            type: "success"
                        });
                    }
                    console.log(res);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        saveEditPassword(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const res = await editUserPassword(this.editPasswordForm);
                    const {code, message} = res;
                    if (code !== 0) {
                        console.log(message);
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.fetchUserInfo();
                        this.emailChanges = false;
                        this.$message({
                            showClose: true,
                            message: "Success",
                            type: "success"
                        });
                    }
                    console.log(res);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
a {
    text-decoration: none;
    color: inherit;
}

button {
    border: none;
    background: none;
}

.accountUserForm {
    p {
        margin-bottom: 2rem;
    }
}

::v-deep .dateEletion .el-input__prefix {
    left: -5px;
}

::v-deep .dateEletion .el-input__inner {
    margin-left: 1.5rem;
    width: 10rem;
}


::v-deep .el-select-dropdown .el-popper {
    min-width: 5rem !important;
}

::v-deep .el-checkbox__label {
    color: #212121;
    font-size: .8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    line-height: 30px;
    word-break: break-all;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #212121;
    font-size: .8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    word-break: break-all;
}

::v-deep .el-checkbox__inner {
    border-color: #212121;
    width: .5rem;
    height: .5rem;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #212121;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #212121;
    border: none;
}

::v-deep .el-checkbox__input {
    vertical-align: top;
    line-height: 2;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    display: none;
}

::v-deep .el-checkbox {
    margin-bottom: 1rem;
    padding: 0 1rem;
}

::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
    padding: 0;
    border-bottom: 1px solid #d8d7d5;
}

::v-deep .el-form-item__label {
    padding: 0;
    line-height: .1rem;
}

::v-deep .el-select {
    width: 100%;
}

::v-deep .el-tabs__nav-wrap::after {
    display: none;
}

::v-deep .el-tabs__nav-scroll {
    display: flex;
}

::v-deep .el-tabs__header {
    margin: 0;
    padding: 1rem 0;
}

::v-deep .el-tabs__item {
    height: .8rem;
    line-height: 0;
    font-size: inherit;
}

::v-deep .el-tabs__item:hover {
    color: inherit;
}

::v-deep .el-tabs__active-bar {
    background: #212121;
    height: 1px;
}

::v-deep .el-tabs__item {
    font-weight: 400;
}

::v-deep .el-tabs__item.is-active {
    color: #212121;
}

.profileForm {
    form {
        ::v-deep .el-checkbox__label {
            line-height: 16px;
        }

        .editUserPasswordButton {
            text-decoration: none;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            padding-bottom: .0625rem;
            border-bottom-color: currentColor;
            padding-bottom: .125rem;
            display: block;
            padding-left: 0;
        }

        .editUserPasswordButton:hover {
            cursor: pointer;
        }

        .editUserPasswordButton {
            display: block;
            margin-bottom: 1rem;
            width: auto;
        }

        .passwordFieldContainer {
            margin: 2rem 0;
        }

        .serverSideErrors {
            -ms-grid-column: 1;
            grid-column-start: 1;
            -ms-grid-column-span: 1;
            grid-column-end: 2;
            -ms-grid-row: 3;
            grid-row-start: 3;
            -ms-grid-row-span: 5;
            grid-row-end: 8;
        }

        .changeEmailPasswordContainer {
            -ms-grid-column: 1;
            grid-column-start: 1;
            -ms-grid-column-span: 1;
            grid-column-end: 2;
            -ms-grid-row: 1;
            grid-row-start: 1;
            -ms-grid-row-span: 2;
            grid-row-end: 3;
        }

        .requiredFieldLabel {
            margin-top: 0;
            -ms-grid-column: 2;
            grid-column-start: 2;
            -ms-grid-column-span: 1;
            grid-column-end: 3;
            -ms-grid-row: 1;
            grid-row-start: 1;
            -ms-grid-row-span: 1;
            grid-row-end: 2;
        }

        .Name {
            -ms-grid-column: 2;
            grid-column-start: 2;
            -ms-grid-column-span: 1;
            grid-column-end: 3;
            -ms-grid-row: 2;
            grid-row-start: 2;
            -ms-grid-row-span: 1;
            grid-row-end: 3;
        }

        .Surname {
            -ms-grid-column: 2;
            grid-column-start: 2;
            -ms-grid-column-span: 1;
            grid-column-end: 3;
            -ms-grid-row: 3;
            grid-row-start: 3;
            -ms-grid-row-span: 1;
            grid-row-end: 4;
        }

        .BirthDate {
            -ms-grid-column: 2;
            grid-column-start: 2;
            -ms-grid-column-span: 1;
            grid-column-end: 3;
            -ms-grid-row: 4;
            grid-row-start: 4;
            -ms-grid-row-span: 1;
            grid-row-end: 5;

            ::v-deep .el-form-item__content {
                display: flex;
                flex-grow: 1;
                justify-content: flex-start;

                .el-select {
                    padding-right: .8rem;
                }

                .el-select:last-child {
                    padding-right: 0;
                }
            }
        }

        .privacyContainer {
            -ms-grid-column: 2;
            grid-column-start: 2;
            -ms-grid-column-span: 1;
            grid-column-end: 3;
            -ms-grid-row: 5;
            grid-row-start: 5;
            -ms-grid-row-span: 1;
            grid-row-end: 6;
            margin-bottom: 1rem;

            a {
                border-bottom: 1px solid currentColor;
            }
        }

        .acceptFlags {
            -ms-grid-column: 2;
            grid-column-start: 2;
            -ms-grid-column-span: 1;
            grid-column-end: 3;
            -ms-grid-row: 6;
            grid-row-start: 6;
            -ms-grid-row-span: 1;
            grid-row-end: 7;
            margin-bottom: 1rem;

            ::v-deep .el-checkbox__input {
                line-height: 1;
            }
        }

        .buttonsRow {
            cursor: pointer;
            -ms-grid-column: 2;
            grid-column-start: 2;
            -ms-grid-column-span: 1;
            grid-column-end: 3;
            -ms-grid-row: 7;
            grid-row-start: 7;
            -ms-grid-row-span: 1;
            grid-row-end: 8;
            margin-top: 2rem;

            button {
                background-color: #212121;
                border-width: 0;
                color: #fff;
                display: block;
                padding: 1rem 3.125rem .9375rem;
                text-align: center;
                width: 100%;
                order: 1;
                margin: 0 auto;
            }
        }
    }
}

.emailchangeBox {
    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 30px;
    }

    button {
        background-color: #212121;
        border-width: 0;
        color: #fff;
        display: block;
        padding: 1rem 3.125rem .9375rem;
        text-align: center;
        width: 100%;
        order: 1;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 768px) {
    .accountUserForm {
        margin: 0 auto;
        max-width: 100%;
        width: 25rem;
    }
    .profileForm {
        form {
            display: grid;
            -ms-grid-columns: 23.4375rem 23.4375rem;
            grid-template-columns: 23.4375rem 23.4375rem;
            -ms-grid-rows: 4.625rem 5rem 5rem 5rem 6.875rem 9.375rem 4.375rem;
            grid-template-rows: 4.625rem 5rem 5rem 5rem 6.875rem 9.375rem 4.375rem;
            grid-column-gap: 5rem;
        }
    }
    .emailchangeBox {
        .mfp-content-flex-item {
            width: 25rem;
            margin: auto;
            max-width: 100%;
        }

        .mandatory {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        .oldEmail {
            margin-bottom: 1rem;
        }
    }
}

@media (min-width: 1280px) {
    .accountUserForm {
        width: 51.875rem;
    }
    .profileForm {
        .requiredFieldLabel, .Name, .Surname, .BirthDate, .privacyContainer, .acceptFlags, .buttonsRow {
            margin-left: 2.5rem;
        }
    }
    .emailchangeBox {
        .mfp-content-holder {
            padding: 3.9375rem calc(4% + .8125rem);
        }
    }
}

@media (max-width: 1280px) {
    .main {
        display: none;
    }
    .accountUserForm {
        margin-top: 3.125rem;

        .smartphone-back-button {
            display: block;
            padding-top: 2rem;
            margin-bottom: 2rem;
            border-top: 1px solid #d8d7d5;

            a {
                margin-left: 1.1875rem;
                position: relative;

                .icon {
                    width: .5rem;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }

                .icon:before {
                    background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/arrow-left.svg);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    content: "";
                    padding: 0 0 100%;
                    display: block;
                }

                span:nth-child(2) {
                    padding-left: .9375rem;
                }
            }
        }

        p, .requiredFieldLabel {
            margin-bottom: 2rem !important;
        }

        p, .changeEmailPasswordContainer, .requiredFieldLabel, .Name, .Surname, .BirthDate, .privacyContainer, .buttonsRow {
            margin: 0 1.1875rem;
        }
    }
    .emailchangeBox {
        margin: 0 1.1875rem;

        .mandatory {
            margin-bottom: 1rem;
        }

        .oldEmail {
            margin-bottom: 1rem;
        }
    }

}
</style>
